
import { Component, Vue } from 'vue-property-decorator';
import Input from '@/components/ui/input/Input.vue';
import Icon from '@/components/ui/icon/Icon.vue';
import Button from '@/components/ui/button/Button.vue';
import ApiClient from '@/api/api.client';

@Component({
    name: 'Recovery',
    components: {
        Input,
        Button,
        Icon,
    },
})
export default class Recovery extends Vue {
    public email = '';
    public isEmailValid: boolean | null = null;
    public submitted = false;

    get canSubmit(): boolean {
        return !!this.email && this.isItEmail(this.email);
    }

    onEmailChange(value: string) {
        this.email = value.trim();
    }

    emailValidator() {
        this.isEmailValid = this.isItEmail(this.email);
    }

    isItEmail(value: string) {
        return /.+@.+\..+/.test(value);
    }

    async onSubmit() {
        if(this.canSubmit) {
            await ApiClient.recovery.reset(this.email)
            .then(
                res => {
                    if(res.status === 200) {
                        this.submitted = true;
                    }
                },
            );
        }
    }
}
